import { EuiHeader, EuiHeaderLink, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { AddAttributeToGroup } from '@features/attributes'
import { noShadow } from '@shared/ui/styles'

export const AttributeGroupHeader: ReactFc = () => {
  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection>{/* TODO: Добавить поиск по группам */}</EuiHeaderSection>

      <EuiHeaderSectionItem>
        <EuiHeaderLink iconType="minusInCircle" color="primary" iconSide="right" disabled>
          Исключить выбранные
        </EuiHeaderLink>
        <AddAttributeToGroup />
      </EuiHeaderSectionItem>
    </EuiHeader>
  )
}
